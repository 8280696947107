nav.navigation {
  display: grid;
  grid-template-areas: "logo logo controls";
  grid-template-columns: min-content auto;
  grid-template-rows: min-content min-content min-content min-content auto;

  width: 100%; // otherwise narrow when fixed
  z-index: 200; // above post labels and notes
  position: relative;


  /* Top bar, always visible */
  .nav-logo {
    grid-area: logo;

    background-color: themed("color-background");
    height: 3.75rem;

    a.nav-logo-link {
      display: flex;
      box-sizing: border-box;

      // Height: 3.75rem
      // - padding  0.125 * 2 = 0.25
      // - image                3.5
      height: 3.5rem;
      width: 4rem;
      margin: 0.125rem;
  
      background-image: url("main-logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: center;
    }
  }

  .nav-controls {
    grid-area: controls;

    display: flex;
    flex-flow: row-reverse;
    align-items: center;

    font-size: 1.15rem;
    background-color: themed("color-background");
    padding-right: 0.25rem;
    height: 3.75rem;

    //   Height: 3.75rem
    // - link padding     0.625  * 2 = 1.25
    // - internal size                 2.5
    & > a {
      display: flex;
      padding: 0.625rem 0.5rem;

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        gap: 0.5rem;
        
        height: 2.5rem;
        min-width: 2.5rem;
        line-height: 1.5rem;
        padding: 0 0.5rem;

        background: themed("color-foreground");
        color: themed("color-link-active");
        border-radius: 0.25rem;
        white-space: nowrap;
      }

      &:hover > span, &:active > span { background: themed("color-section"); }
      &:focus { outline: none; }
    }

    a.simple-avatar {
      .avatar-button {
        padding: 0;
        gap: 0;

        .avatar-name {
          padding: 0.5rem;

          @include window-smaller-than(32rem) {
            display: none;
          }
        }

        .avatar-image {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          width: 2.5rem;
          height: 2.5rem;
    
          box-sizing: border-box;
          border-radius: 0.25rem;
          background: themed("color-section");

          img {
            width: 2.5rem;
            height: 2.5rem;

            border-radius: 0.25rem;
            object-fit: cover;

            z-index: 1; // above the letter
          }

          &::before {
            content: attr(data-name);
            position: absolute;
            font-size: 1.5rem;
          }
        }

        @include window-smaller-than(32rem) {
          &.sign-in .avatar-image {
            background: themed("color-foreground");
          }
        }
      }
    }
  }

  /* Offset elements on the left and bottom */
  // Needed to track clicks outside the menu area
  @each $name in ("left", "bott") {
    .nav-offset-#{$name} {
      grid-area: off#{$name};
      display: none; // flex
      background: #00000050;
    }
  }

  /* Toggled menus, hidden by default */
  // Naming areas
  @each $name in (primary, secondary, tools, help) {
    .nav-#{$name} {
      grid-area: $name;
      display: none;

      li {
        padding: 0;

        & > a {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          white-space: nowrap;

          svg {
            margin: -0.25rem 0;
            color: themed("color-link-active");
          }
        }
      }
    }
  }

  // Common top
  .nav-primary, .nav-secondary {
    flex-flow: column;

    // Prevent the tools / help buttons from being pushed
    // way too low on pages with a lot of secondary links
    height: 422px;
    overflow-y: scroll;

    li a {
      justify-content: start;
    }
  }

  .nav-primary {
    background-color: themed("color-section");
    font-size: 1.25rem;

    li > a {
      border-bottom: 1px solid themed("color-foreground");
      line-height: 1.25rem;
      padding: 1rem 0.5rem;
    }
    li.current a { background-color: themed("color-foreground"); }

    li.nav-hidden { display: none; }
  }

  .nav-secondary {
    background-color: themed("color-foreground");
    font-size: 1.2rem;

    li {
      & > a {
        border-bottom: 1px solid themed("color-section");
        line-height: 1.2rem;
        padding: 0.7rem;

        white-space: wrap; // forum menus are long
      }

      &.divider {
        border-bottom: 1px solid themed("color-section");
        height: 0.25rem;
      }

      form input[type="text"] {
        width: 100%;
        box-sizing: border-box;

        font-size: 1.25rem;
        padding: 0.5rem 0.5rem;
      }
    }
  }

  // Common bottom
  .nav-tools, .nav-help {
    grid-template-rows: min-content;
    font-size: 1.1rem;
    gap: 1rem;

    background-color: themed("color-section");

    li > a {
      justify-content: center;
      border-radius: 0.25rem;

      padding: 0.7rem 1rem;
      line-height: 1.1rem;
    }
  }

  .nav-tools {
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid themed("color-foreground");
    padding: 1rem 1rem 0.5rem;

    li {
      a { background: themed("color-section-lighten-5"); }
      &.nav-tools-login { grid-column: 1 / -1; }
    }

    &.anonymous li.nav-tools-themes {
      grid-column: 1 / -1;
    }
  }

  .nav-help {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.5rem 1rem 1rem;

    li {
      a { background: themed("color-section-darken-5"); }
      &.nav-help-discord,
      &.nav-help-subscribestar {
        grid-column: 1 / -1;
      }

      & > a img {
        height: 1.5rem;
        margin: -0.2rem 0;
      }

      // Hack to put the wiki/help links before discord/sstar on mobile
      // but still have "more" at the end on desktop
      &#nav-more {
        grid-row: 1;
        grid-column: 3;
      }
    }
  }
}


// Sticky header
body[data-th-sheader="true"] nav.navigation {
  position: sticky;
  top: 0;
}


// Forum notification
body[data-th-forumnotif="true"] nav.navigation .nav-primary li.forum-updated {
  position: relative;

  &::after {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;

    background: palette("text-red");

    position: absolute;
    right: 0.2rem;
    top: 1.25rem;
  
    @include window-larger-than(800px) {
      top: 0.2rem;
    }
  }
}


// Mobile toggle
html.nav-toggled {

  height: 100%;
  overflow: hidden;

  body { padding-top: 3.75rem; }

  nav.navigation {
    grid-template-areas:
      "logo    logo    controls" 
      "offleft primary secondary "
      "offleft tools   tools     "
      "offleft help    help      "
      "offbott offbott offbott   ";
    grid-template-columns: auto minmax(auto, 180px) minmax(auto, 180px);
    position: fixed;
    top: 0;
    height: 100vh;
    max-width: 100vw; // prevent bug when page overflows viewport

    // Allow scrolling when the menu is too long
    overflow-y: scroll;

    .nav-primary, .nav-secondary, .nav-offset-left, .nav-offset-bott {
      display: flex;
    }
    .nav-tools, .nav-help {
      display: grid;
    }
    .nav-primary, .nav-tools, .nav-help {
      box-shadow: -3px 3px 5px -1px themed("color-background");
    }
  }
}


// Desktop
nav.navigation, html.nav-toggled nav.navigation {
  @include window-larger-than(50rem) {
    grid-template-areas:
      "logo primary   help      help  controls" 
      "logo secondary secondary tools controls";
    grid-template-columns: min-content min-content minmax(0, 1fr) min-content 3.25rem;
    grid-template-rows: 1.5rem 1.75rem;

    padding: 0 1rem 0.5rem;
    box-sizing: border-box;
    height: unset;

    background: themed("color-background");
    overflow-y: hidden; // overrides mobile hack allowing the menu scrolling

    .nav-logo a.nav-logo-link {
      height: 3.25rem;
      width: 3.25rem;
      margin: 0.25rem 0.5rem 0 0;
    }

    .mobile { display: none; }

    // All link ribbons
    .desktop {
      display: flex;
      flex-flow: row;

      font-size: 0.875rem;
      box-shadow: unset;

      li a {
        display: flex;
        align-items: center;
        height: 100%;

        padding: 0 0.625rem;
        white-space: nowrap;
      }
    }

    .nav-primary {
      background: unset;
      height: unset;
      padding-left: 0.25rem;
      overflow-y: unset;

      li a {
        border-bottom: 0;
        svg { display: none; }
      }
    }

    .nav-secondary {
      height: unset;

      padding: 0 0.25rem;
      border-radius: 0.25rem 0 0 0.25rem;
      overflow-y: unset;

      overflow: hidden; // Silly fix for too many links
      z-index: 1; // above the avatar

      li {
        a { border-bottom: 0; }

        &.divider {
          display: flex;
          align-items: center;
          height: 100%;

          &::after { content: "|"; }
        }

        form input[type="text"] {
          width: 12rem;
          padding: 0.25rem 0.5rem;
          font-size: 1rem;
          border-radius: 0.25rem;
        }
      }
    }

    .nav-tools, .nav-help {
      padding: 0;
      background: unset;
      border: none;
      gap: 0;

      li a {
        gap: 0.25rem;

        background: unset;
        text-align: unset;
        border-radius: 0;
      }
    }

    .nav-tools {
      background: themed("color-foreground");
      z-index: 1;
      border-radius: 0 0.25rem 0.25rem 0;
      margin-right: 0.25rem;

      li {
        a {
          padding: 0 0.5rem;

          svg {
            color: themed("color-link");
            height: 1.25rem;
            width: 1.25rem;
          }
          &:hover svg { color: themed("color-link-hover"); }
        }

        &.nav-tools-themes, &.nav-tools-settings {
          a span { display: none; }
        }
      }
    }

    .nav-help {

      // At small resolutions, overflow can
      // cause scrollbars to appear
      overflow: hidden;

      li a img { display: none; }
      li.current a {
        background-color: themed("color-foreground");
      }
    }

    .nav-controls {
      position: absolute;
      right: 0;
      top: 0;

      height: 3.25rem;
      box-sizing: border-box;
      padding: 0.25rem 0 0;

      #nav-toggle { display: none; }

      a.simple-avatar {
        padding: 0;
        height: 100%;

        .avatar-button {
          background: none;
          color: inherit;
          align-items: start;
          font-size: 0.875rem;
          line-height: 0.875rem;

          .avatar-name {
            padding: 0 0.5rem;
          }
  
          .avatar-image {
            height: 3rem;
            width: 3rem;
            background: themed("color-foreground");

            img {
              width: 2.75rem;
              height: 2.75rem;
            }
          }
        }
      }
    }

    // Icon collapse
    // Stage 1: discord and subscribestar buttons
    .collapse-1 { display: none; }
    @include window-larger-than(77rem) {
      .collapse-1 { display: flex; }
    }
    
    // Stage 2: account label
    .collapse-2 .avatar-name { display: none; }
    @include window-larger-than(65rem) {
      .collapse-2 .avatar-name { display: unset; }
    }
  }
}

// Tweak for the secondary menu on desktop
body.c-static.a-home {
  @include window-larger-than(50rem) {

    nav.navigation {

      // Center and align the navbar
      grid-template-areas: "logo primary help controls";
      grid-template-columns: repeat(4, min-content);
      justify-content: center;

      // Remove padding to prevent a scrollbar
      // at low desktop resolutions
      padding: 0 0 0.5rem;

      #nav-subscribestar, #nav-discord, .nav-secondary, .nav-tools { display: none; }
    }

    // Match the background colors
    nav.navigation, menu.nav-logo, menu.nav-secondary, menu.nav-controls {
      background: unset;
    }
    menu.nav-tools {
      background: var(--bg-color);
    }
    menu.nav-controls {
      position: static;
      height: unset;
      padding: 0;
      .avatar-button {
        height: unset;
        align-items: center;
        .avatar-image { display: none; }
      }
    }
  }

  @include window-smaller-than(50rem) {
    // Only show the primary navbar on mobile
    // since the secondary is empty anyways
    nav.navigation {
      grid-template-areas:
        "logo    logo    controls" 
        "offleft primary primary " 
        "offleft tools   tools   " 
        "offleft help    help    " 
        "offleft offbott offbott ";

      .nav-secondary { display: none; }
    }
  }
}
