.profile-avatar {
  .avatar-image {
    display: flex;
    width: 5rem;
    height: 5rem;

    position: relative;

    background: themed("color-section");
    @include st-radius;

    // Letter if no avatar image
    &::after {
      content: attr(data-initial);

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 5rem;
      font-weight: bold;
      color: themed("color-foreground");

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    // On top of the letter
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;

      @include st-radius;
    }
  }
}
