
table.striped {
  width: 100%;

  // DText formatting fix
  *:last-child {
    margin: 0;
  }

  td, th {
    padding: 4px 6px;
  }

  tbody {
    tr {
      td {
        padding: $padding-050 $padding-025;

        &.full-width-link {
          padding: 0;

          > a {
            width: 100%;
            display: inline-block;
            padding: $padding-050 $padding-025;
          }
        }
      }
      background-color: themed("color-section");
      border-bottom: 1px solid themed("color-table-border");
      &:hover {
        background-color: themed("color-section-lighten-10");
      }
      &.selected {
        background-color: themed("color-section-lighten-10");
      }
    }
  }

  .number, .links {
    text-align: right;
  }

  thead {
    tr {
      background-color: themed("color-section-darken-5");
      border-bottom: 1px solid themed("color-foreground");
    }

    th {
      font-weight: bold;
      text-align: left;
      color: themed("color-text-table-header");
    }
  }
}

/*
 * A table where one column expands to fill the screen, while the
 * other columns shrink to fit their contents.
 */
table.autofit {
  td, th, .col-fit {
    white-space: nowrap;
    padding-right: 2em;
  }

  .col-expand {
    white-space: normal;
    width: 100%;
  }

  .col-normal {
    white-space: normal;
  }
}

table.search {
  tr {
    height: 2em;
  }

  th {
    text-align: right;
    padding-right: $base-padding;
    vertical-align: top;
  }

  td {
    vertical-align: top;
  }
}

table.aligned-vertical {
  tr {
    height: 1.75em;
  }
}
