tabs-menu, tabs-content {
  max-width: 100rem;
}

#profile-tabs[data-has-about="false"][data-has-artinfo="false"] {
  display: none;
}

.profile-about-section, .profile-artinfo-section {
  flex-flow: column;
  align-items: start;

  tab-head {
    font-weight: bold;
    font-size: 1rem;
  }
}

@include window-larger-than(50rem) {
  tabs-menu { display: none; }
  tabs-content {
    display: grid;
    grid-template-columns: 15rem 1fr;
    grid-template-rows: min-content min-content;
    gap: 1rem;
  }

  .profile-user-info {
    display: grid !important;
    grid-template-areas: unset;
    grid-row: 1 / 4;
    height: min-content;
  }

  .profile-about-section {
    display: flex !important;
    grid-column: 2 / -1;
  }

  .profile-artinfo-section {
    display: flex !important;
    grid-column: 2 / -1;
  }
}