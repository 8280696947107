// Standard button
// Could be applied to either a button or a link
// Semi-expected to have an icon
.st-button {
  $button-font-size: st-value(100);
  $button-background: themed("color-section-lighten-5");
  $button-background-hover: themed("color-section-lighten-10");
  $button-text-color: themed("color-text");

  $button-stealth-color: themed("color-link");
  $button-stealth-color-hover: themed("color-link-hover");

  display: flex;
  gap: st-value(100) / 4;
  border-radius: radius(025);

  // Button final size
  // Font           1rem
  // Padding  2 * 0.5rem
  font-size: st-value(100);
  line-height: st-value(100);
  padding: st-value(100) / 2;
  height: st-value(100) * 2;
  box-sizing: border-box;

  // TODO What if button is on a light background
  background: $button-background;
  color: $button-text-color;
  &:hover, &:active { background: $button-background-hover; }

  & > svg {
    // Icon should be slightly larger than text,
    // with padding to fill the entire button height
    height: st-value(100) * 1.5;      // 1.5rem
    width: st-value(100) * 1.5;       // 1.5rem
    margin: -#{st-value(100) / 2} 0;  // 0.5rem
    padding: st-value(100) / 4;       // 0.25rem

    border-radius: radius(025);
  }
  & > span {
    text-align: left;

    // Do not overflow text
    overflow: hidden;
    flex: 1;
  }

  // Full width button
  &.w100 { width: 100%; }

  &.stealth {
    background: none;
    padding: (st-value(100) / 2) 0;

    svg { background: $button-background; }
    span { color: $button-stealth-color; }
    &:hover {
      svg { background: $button-background-hover; }
      span { color: $button-stealth-color-hover; }
    }
  }

  &.icon {
    padding: (st-value(100) / 2) 0;
  }
}


// Colored buttons
.st-button.danger {
  background: palette("background-red");
  &:hover, &:active {
    background: palette("background-red-d5")
  }
}



// Toggle switch
label.st-toggle {
  display: flex;
  width: 2.5rem;
  height: 0.75rem;
  box-sizing: border-box;
  position: relative;

  background-color: themed("color-foreground");
  // transition: background-color 200ms;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0;

  box-shadow: inset 0 0 0.25rem #00000060;

  &::after {
    content: "";
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -0.125rem;
    left: 0rem;

    background: themed("color-link");
    border-radius: 0.25rem;
    transition: left 100ms, background-color 200ms;
  }
}
input[type="checkbox"][disabled].st-toggle + label.st-toggle {
  background: palette("background-grey");
  cursor: not-allowed;
}
input[type="checkbox"].st-toggle { display: none; }
input[type="checkbox"].st-toggle:checked + label.st-toggle {
  // background-color: palette("background-green-d5");
  &::after {
    content: "";
    left: 1.5rem;
    background: themed("color-link-active");
  }
}
